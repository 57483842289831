var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "is-full-page": _vm.fullPage,
          loader: _vm.loader,
          color: _vm.color
        }
      }),
      this.authenticated ? _c("Navbar") : _vm._e(),
      !this.authenticated ? _c("Callback") : _vm._e(),
      this.authenticated ? _c("ContentArea") : _vm._e(),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }