<template>
  <div class="home">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
      :color="color"
    />
    <Navbar v-if="this.authenticated"/>
    <Callback v-if="!this.authenticated" />
    <ContentArea v-if="this.authenticated"/>
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import ContentArea from '@/components/ContentArea'
import Callback from '@/components/Callback'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      authenticated: false,
      isLoading: true,
      fullPage: true,
      loader: 'bars',
      color: '#ff681d'
    }
  },
  computed: {
    ...mapGetters({
      mailUserName: 'mailUserName'
    })
  },
  watch: {
    mailUserName (newValue, oldValue) {
      if (newValue !== null) {
        this.isLoading = false
        this.authenticated = true
      }
    }
  },
  components: {
    Loading,
    Navbar,
    ContentArea,
    Callback
  }
}
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
}
</style>
